<template>
  <el-dialog
    :visible="dialogTransferJob"
    width="50%"
    title="Transfer Job"
    @open="handleTransferJobsDialogOpen"
  >
    <!-- @close="handleTransferJobsDialogClose" -->
    <div>
      <el-form>
        <el-form-item label="Selected Barge">
          <el-input v-model="selectedBargeName" disabled />
        </el-form-item>
        <el-form-item label="Transfer To" style="margin-top: 18px">
          <el-select
            filterable
            style="width: 100%"
            v-model="bargeShipId"
            @visible-change="visibleChange"
            :loading="availableBargesLoading"
            @change="handleChange"
          >
            <el-option
              v-for="item in filteredBargeList"
              :disabled="jobData.type === 3 && item.id === jobData.vesselShipId"
              :key="item.id"
              :label="item.shipName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <div class="el-dialog__footer">
      <el-button outline round @click="handleTransferJobsDialogClose"> Cancel </el-button>
      <el-button
        round
        type="primary"
        :disabled="!bargeShipId"
        @click="transferBarge"
        :loading="loading"
      >
        Submit
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
export default {
  name: "DialogTransferJob",
  props: {
    dialogTransferJob: Boolean,
    availableBargesLoading: Boolean,
    availableBargesOptions: Array,
    jobData: Object,
  },
  data() {
    return {
      bargeShipId: null,
      bargeImo: null,
      selectedBargeName: null,
      loading: false,
    };
  },
  computed: {
    filteredBargeList() {
      return this.availableBargesOptions.filter(
        (item) => item.id !== this.jobData.bargeShipId
      );
    },
  },
  methods: {
    handleTransferJobsDialogOpen() {
      this.selectedBargeName = this.jobData.bargeName;
    },
    handleTransferJobsDialogClose() {
      this.selectedBargeName = null;
      this.bargeShipId = null;
      this.bargeImo = null;
      this.$emit("handleTransferJobsDialogClose");
    },
    visibleChange(isVisible) {
      if (isVisible) {
        this.$emit("checkAvailableBargesOptions", isVisible, this.jobData);
      }
    },
    handleChange(value) {
      const barge = this.filteredBargeList.find((item) => item.id == value);
      this.bargeImo = barge ? barge.imo : null;
    },

    transferBarge() {
      this.loading = true;
      let url = `ebdn/jobs/${this.jobData.id}/transfer?bargeShipId=${this.bargeShipId}`;

      if (this.bargeImo?.trim()?.length > 0) {
        url += `&bargeImo=${this.bargeImo}`;
      }

      this.$request
        .put({
          url,
        })
        .finally(() => {
          this.loading = false;
          this.handleTransferJobsDialogClose();
          this.$emit("handleJobTableRefresh");
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.el-dialog__footer {
  padding: 14px 0;
}
/deep/.el-dialog__body {
  padding: 10px 20px;
}
</style>
